<template>
    <page v-if="retorno">
        <v-breadcrumbs slot="breadcrumb" class="pa-0">
            <v-breadcrumbs-item exact :to="{name:'dashboard'}">Início</v-breadcrumbs-item>
            <v-breadcrumbs-divider>></v-breadcrumbs-divider>
            <v-breadcrumbs-item exact :to="{name:'financeiro'}">Financeiro</v-breadcrumbs-item>
            <v-breadcrumbs-divider>></v-breadcrumbs-divider>
            <v-breadcrumbs-item exact :to="{name:'conta'}">Conta</v-breadcrumbs-item>
            <v-breadcrumbs-divider>></v-breadcrumbs-divider>
            <v-breadcrumbs-item exact :to="{name:'retornos'}">Retornos</v-breadcrumbs-item>
        </v-breadcrumbs>
        <div>
            <span class="title font-weight-regular d-inline-flex" style="line-height: normal; align-items: center">
                Nº: {{retorno.numeroAvisoBancario}}
                <v-btn :loading="downloadingRetorno" :disabled="downloadingRetorno"
                       class="ml-2" small outlined icon color="indigo" @click="downloadRetorno">
                    <v-icon small>mdi-file-download-outline</v-icon>
                </v-btn>
            </span>
            <div style="opacity: 0.6; float: right" class="d-inline-flex align-center">
                <div class="d-flex align-center">
                    <div>
                        <p class="mb-0 grey--text " style="font-size:10px; line-height: 12px">Data Gravação Arquivo</p>
                        <p class="mb-0 caption grey--text text--darken-2" style="line-height: 12px">{{retorno.dataGravacaoArquivo | moment("DD/MM/YYYY")}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex mt-6  mb-6">
            <v-alert class="text-right mb-0 mr-4 py-0" text color="teal" >
                <span class="overline">Valor Pago</span>
                <span class="headline d-block">
                    {{retorno.valorTotalPago | formatNumber}}
                </span>
            </v-alert>
        </div>

        <v-card outlined class="mt-6 mb-6" v-if="retorno.transacoes">
            <v-card-title class="pb-0">
                Transações
                <span v-if="retorno.transacoes.length > 0" class="ml-2 subtitle-2 font-weight-regular">{{retorno.transacoes.length}} itens</span>
            </v-card-title>
            <v-card-text class="pa-0">
                <!--<v-list class="py-0" :three-line="$vuetify.breakpoint.smAndUp" :two-line="$vuetify.breakpoint.xsOnly" v-if="agencia.fiscalizacoes && agencia.fiscalizacoes.length > 0">-->
                <v-list class="mt-2 py-0" two-line>
                    <template v-for="(transacao, index) in retorno.transacoes">
                        <v-list-item two-line @click="abrirTransacao(transacao.id)">
                            <v-list-item-content>
                                <template v-if="transacao.titulo">
                                    <v-list-item-title>{{transacao.titulo.nomePagador}}</v-list-item-title>
<!--                                    <v-list-item-subtitle>AG: {{transacao.titulo.agencia}}-{{transacao.titulo.agenciaDigito}} C/C: {{transacao.titulo.conta}}-{{transacao.titulo.contaDigito}}</v-list-item-subtitle>-->
                                </template>
                                <template v-else>
                                    <v-list-item-title class="orange--text">Nenhum título encontrado para essa transação</v-list-item-title>
                                </template>
                                <v-list-item-subtitle :class="corOcorrencia(transacao.identificacaoOcorrenciaTexto)">{{transacao.identificacaoOcorrenciaTexto}}</v-list-item-subtitle>
                                <v-list-item-subtitle v-if="$vuetify.breakpoint.xsOnly" class="indigo--text">
                                    <b class="grey--text text--darken-1">Valor Pago</b> {{transacao.valorPago | formatNumber}}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action v-if="!$vuetify.breakpoint.xsOnly">
                                <v-list-item-action-text class="indigo--text" >
                                    <b class="grey--text text--darken-1">Valor Pago</b> {{transacao.valorPago | formatNumber}}
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider ></v-divider>
                    </template>
                </v-list>
            </v-card-text>
        </v-card>
        <transacao-dialog ref="transacaoDialog" />
    </page>
</template>

<script>
import page from "@/components/Page";
import gql from "graphql-tag";
import TransacaoDialog from "@/views/financeiro/conta/retorno/TransacaoDialog";

export default {
    name: "View",
    components:{
        TransacaoDialog,
        page,
    },
    data() {
        return {
            retorno: null,
            loading: false,
            downloadingRetorno: false,
        }
    },
    mounted() {
        this.getRetorno()
    },
    methods: {
        getRetorno() {
            const id = this.$route.params.id;
            this.$apollo.query({
                query: gql`query($id: Int!) {
                        retorno(id: $id){
                            id
                            numeroAvisoBancario
                            dataGravacaoArquivo
                            valorTotalPago
                            transacoes{
                                id
                                identificacaoOcorrenciaTexto
                                valorPago
                                dataOcorrencia
                                titulo{
                                    id
                                    nomePagador
                                    agencia
                                    agenciaDigito
                                    conta
                                    contaDigito
                                    valor
                                }
                            }
                            createdAt
                        }
                    }`,
                variables: {id},
            }).then((result) => {
                this.retorno = result.data.retorno;
                document.title = "Nº: " + this.retorno.numeroAvisoBancario + " - JF Assessoria Rural"
            });
        },
        abrirTransacao(id){
            this.$refs.transacaoDialog.openDialog(id);
        },
        downloadRetorno(){
            this.downloadingDanfe = true;
            this.$axios.get('/cobrancas/' + this.retorno.id + '/download_retorno', {responseType: 'blob'}).then((response) => {
                const fileLink = document.createElement('a');
                fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
                const fileName = response.headers['content-disposition'].split('filename=')[1];
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
                this.downloadingDanfe = false;
            }).catch(() => {
                this.downloadingDanfe = false;
                this.$dialog.error({
                    text: 'Não foi possível baixar o arquivo. Contate o desenvolvedor.',
                    title: 'Ops',
                });

            });
        },
        corOcorrencia(ocorrencia){
            switch (ocorrencia){
                case 'Entrada Confirmada':
                    return 'green--text'
                case 'Entrada Rejeitada':
                    return 'red--text'
                case 'Liquidação Normal (sem motivo)a':
                    return 'light-blue--text text--darken-3'
                case 'Desagendamento do Débito Automático':
                    return 'blue-grey--text text--darken-2'
                default:
                    return ''
            }
        }
    }
}
</script>

<style scoped>

</style>