<template>
    <v-dialog v-model="dialog" persistent scrollable max-width="600px">
        <v-card v-if="dialog">
            <v-card-title>
                <span class="headline">Transação</span>
            </v-card-title>
            <v-card-text class="pb-0" v-if="transacao">
                <div class="mb-4">
                    <v-row :dense="$vuetify.breakpoint.mobile"  >
                        <v-col sm="3" cols="6">
                            <span class="d-block caption font-weight-medium">Nosso Número</span>
                            <span>{{transacao.nossoNumero}}</span>
                        </v-col>
                        <v-col sm="3" cols="6">
                            <span class="d-block caption font-weight-medium">Número</span>
                            <span>{{transacao.numero}}</span>
                        </v-col>
                        <v-col sm="3" cols="6">
                            <span class="d-block caption font-weight-medium">Valor Pago</span>
                            <span >{{transacao.valorPago | formatNumber}}</span>
                        </v-col>
                        <v-col sm="3" cols="6">
                            <span class="d-block caption font-weight-medium">Data Ocorrência</span>
                            <span >{{transacao.dataOcorrencia | moment('DD/MM/YYYY')}}</span>
                        </v-col>
                    </v-row>
                    <v-row :dense="$vuetify.breakpoint.mobile" >
                        <v-col >
                            <span class="d-block caption font-weight-medium">Identificação Ocorrência
                                <span class="font-weight-light">cod.: {{transacao.identificacaoOcorrencia}}</span>
                            </span>
                            <span :class="corOcorrencia(transacao.identificacaoOcorrenciaTexto)">{{transacao.identificacaoOcorrenciaTexto}}</span>
                        </v-col>

                    </v-row>
                    <v-row :dense="$vuetify.breakpoint.mobile" >
                        <v-col >
                            <span class="d-block caption font-weight-medium">Motivos Rejeição
                                <span class="font-weight-light">cod.: {{transacao.motivosRejeicao}}</span>
                            </span>
                            <!--                            <span>{{transacao.motivosRejeicao}}</span>-->
                            <template v-for="texto of transacao.motivosOcorrenciaTexto">
                                <p class="mb-0 font-weight-light caption">- {{texto}}</p>
                            </template>
                        </v-col>
                    </v-row>
                </div>
                <span class="body-1 black--text mr-2">Título</span>
                <div class="grey lighten-5 py-1 px-3 mt-1" v-if="transacao.titulo">
                    <v-row>
                        <v-col cols="4" class="pb-0">
                            <span style="white-space: nowrap" class="d-block caption font-weight-medium">Serviço</span>
                            <v-btn x-small class="primary" v-if="transacao.titulo.projeto"
                                   :to="{ name: 'projeto_view', params: { id: parseInt(transacao.titulo.projeto.id) }}">
                                Abrir Projeto
                            </v-btn>
                        </v-col>
                        <v-col cols="4" class="pb-0">
                            <span class="d-block caption font-weight-medium">Remessa</span>
                            <span><router-link :to="{ name: 'remessa_view', params: { id: parseInt(transacao.titulo.remessa.id) }}">{{transacao.titulo.remessa.numero}}</router-link></span>
                        </v-col>
                        <v-col cols="4" class="pb-0">
                            <span class="d-block caption font-weight-medium">Emissão</span>
                            <span style="white-space: nowrap">{{transacao.titulo.emissao | moment('DD/MM/YYYY HH:mm')}}</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col  cols="12" sm="auto" class="pb-0 shrink ">
                            <span class="d-block caption font-weight-medium">Pagador</span>
                            <span >{{transacao.titulo.nomePagador}}</span>
                        </v-col>
                        <v-col  cols="12" sm="auto" class="pb-0 grow">
                            <span class="d-block caption font-weight-medium">Inscrição</span>
                            <span>{{formatCpfCnpj(transacao.titulo.inscricaoPagador)}}</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pb-0 shrink">
                            <span class="d-block caption font-weight-medium">Agência</span>
                            <span>{{transacao.titulo.agencia}}-{{transacao.titulo.agenciaDigito}}</span>
                        </v-col>
                        <v-col cols="6" sm="auto" class="pb-0 grow">
                            <span class="d-block caption font-weight-medium">Conta</span>
                            <span>{{transacao.titulo.conta}}-{{transacao.titulo.contaDigito}}</span>
                        </v-col>
                        <v-col cols="6" sm="auto" class="pb-0 grow">
                            <span class="d-block caption font-weight-medium">Valor</span>
                            <span>{{transacao.titulo.valor | formatNumber}}</span>
                        </v-col>
                        <v-col cols="6" sm="3" class="pb-0 shrink">
                            <span style="white-space: nowrap" class="d-block caption font-weight-medium">Data Vencimento</span>
                            <span>{{transacao.titulo.vencimento | moment('DD/MM/YYYY')}}</span>
                        </v-col>
                    </v-row>
                </div>
                <div v-else>
                    <span class="orange--text">Nenhum título encontrado para essa transação</span>
                </div>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <div class="float-right">
                        <v-btn text color="primary" @click="closeDialog">
                            Ok
                        </v-btn>
                    </div>
                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "TransacaoDialog",
    data() {
        return {
            dialog: false,
            loading: false,
            transacao: null,
        }
    },
    methods: {
        openDialog(id) {
            this.transacao = null;
            this.dialog = true;
            this.getTransacao(id)
        },
        closeDialog() {
            this.dialog = false;
        },
        getTransacao(id){
            this.loading = true;
            this.$apollo.query({
                query: gql`query($id: Int!) {
                        transacao(id: $id){
                            id
                            identificacaoOcorrencia
                            identificacaoOcorrenciaTexto
                            motivosRejeicao
                            motivosOcorrenciaTexto
                            valorPago
                            dataOcorrencia
                            nossoNumero
                            numero
                            titulo{
                                id
                                nomePagador
                                inscricaoPagador
                                agencia
                                agenciaDigito
                                conta
                                contaDigito
                                valor
                                emissao
                                vencimento
                                remessa{
                                    id
                                    numero
                                }
                                projeto{
                                    id
                                }
                            }
                        }
                    }`,
                variables: {id},
            }).then((result) => {
                this.transacao = result.data.transacao;
            }).finally(() => {
                this.loading = false;
            })
        },
        formatCpfCnpj(cpfCnpj){
            let formatCpf = (cpf) => {
                return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
            }
            let formatCnpj = (cnpj) => {
                return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1 $2 $3/$4-$5")
            }
            if(cpfCnpj.length === 11){
                return formatCpf(cpfCnpj)
            }else{
                return formatCnpj(cpfCnpj)
            }
        },
        corOcorrencia(ocorrencia){
            switch (ocorrencia){
                case 'Entrada Confirmada':
                    return 'green--text'
                case 'Entrada Rejeitada':
                    return 'red--text'
                default:
                    return ''
            }
        }
    }
}
</script>

<style scoped>

</style>